var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-card",
        [
          _c(
            "a-tabs",
            {
              staticClass: "inline-tab-view",
              attrs: { "default-active-key": "1" },
              on: { change: _vm.tabChange },
            },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "规范" } },
                [
                  _c("guide", {
                    attrs: {
                      "is-preview": _vm.isPreview,
                      addPermissionString: "gz:add",
                      editPermissionString: "gz:edit",
                      deletePermissionString: "gz:delete",
                      viewPermissionString: "gz:view",
                      "props-filters": { typeId: 2 },
                      propsIpagination: _vm.ipagination,
                      propsUrl: _vm.url,
                    },
                    on: {
                      loadData: function ($event) {
                        return _vm.loadDataOver($event, { typeId: 2 })
                      },
                      tableConfirm: _vm.tableConfirm,
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "2",
                  attrs: {
                    tab: "教程",
                    "force-render": "",
                    disabled: _vm.isPreview && !_vm.confirmList.includes(2),
                  },
                },
                [
                  _c("guide", {
                    attrs: {
                      "is-preview": _vm.isPreview,
                      addPermissionString: "jc:add",
                      editPermissionString: "jc:edit",
                      deletePermissionString: "jc:delete",
                      viewPermissionString: "jc:view",
                      "props-filters": { typeId: 3 },
                      propsIpagination: _vm.ipagination,
                      propsUrl: _vm.url,
                    },
                    on: {
                      loadData: function ($event) {
                        return _vm.loadDataOver($event, { typeId: 3 })
                      },
                      tableConfirm: _vm.tableConfirm,
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "3",
                  attrs: {
                    tab: "词汇",
                    "force-render": "",
                    disabled:
                      _vm.isPreview &&
                      (!_vm.confirmList.includes(2) ||
                        !_vm.confirmList.includes(3)),
                  },
                },
                [
                  _c("guide", {
                    attrs: {
                      "is-preview": _vm.isPreview,
                      addPermissionString: "ch:add",
                      editPermissionString: "ch:edit",
                      deletePermissionString: "ch:delete",
                      viewPermissionString: "ch:view",
                      "props-filters": { typeId: 4 },
                      propsIpagination: _vm.ipagination,
                      propsUrl: _vm.url,
                    },
                    on: {
                      loadData: function ($event) {
                        return _vm.loadDataOver($event, { typeId: 4 })
                      },
                      tableConfirm: _vm.tableConfirm,
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "4",
                  attrs: {
                    tab: "规则",
                    "force-render": "",
                    disabled:
                      _vm.isPreview &&
                      (!_vm.confirmList.includes(2) ||
                        !_vm.confirmList.includes(3) ||
                        !_vm.confirmList.includes(4)),
                  },
                },
                [
                  _c("rule", {
                    attrs: {
                      "is-preview": _vm.isPreview,
                      "props-filters": { typeId: 0 },
                      propsIpagination: _vm.ipagination,
                    },
                    on: {
                      loadData: function ($event) {
                        return _vm.loadDataOver($event, { typeId: 0 })
                      },
                      tableConfirm: _vm.tableConfirm,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }