<template>
  <div>
    <!-- <h1 style="position: absolute; top: 80px; z-index: 1">人员须知编辑页面，开发中...🔨</h1> -->
    <a-card>
      <a-tabs
        class="inline-tab-view"
        default-active-key="1"
        @change="tabChange"
      >
        <a-tab-pane
          key="1"
          tab="规范"
        >
          <guide
            @loadData="loadDataOver($event, { typeId: 2 })"
            :is-preview="isPreview"
            @tableConfirm="tableConfirm"
            addPermissionString="gz:add"
            editPermissionString="gz:edit"
            deletePermissionString="gz:delete"
            viewPermissionString="gz:view"
            :props-filters="{ typeId: 2 }"
            :propsIpagination="ipagination"
            :propsUrl="url"
          ></guide>
        </a-tab-pane>
        <a-tab-pane
          key="2"
          tab="教程"
          force-render
          :disabled="isPreview && !confirmList.includes(2)"
        >
          <guide
            @loadData="loadDataOver($event, { typeId: 3 })"
            :is-preview="isPreview"
            @tableConfirm="tableConfirm"
            addPermissionString="jc:add"
            editPermissionString="jc:edit"
            deletePermissionString="jc:delete"
            viewPermissionString="jc:view"
            :props-filters="{ typeId: 3 }"
            :propsIpagination="ipagination"
            :propsUrl="url"
          ></guide>
        </a-tab-pane>
        <a-tab-pane
          key="3"
          tab="词汇"
          force-render
          :disabled="isPreview && (!confirmList.includes(2) || !confirmList.includes(3))"
        >
          <guide
            @loadData="loadDataOver($event, { typeId: 4 })"
            :is-preview="isPreview"
            @tableConfirm="tableConfirm"
            addPermissionString="ch:add"
            editPermissionString="ch:edit"
            deletePermissionString="ch:delete"
            viewPermissionString="ch:view"
            :props-filters="{ typeId: 4 }"
            :propsIpagination="ipagination"
            :propsUrl="url"
          ></guide>
        </a-tab-pane>
        <a-tab-pane
          key="4"
          tab="规则"
          force-render
          :disabled="isPreview && (!confirmList.includes(2) || !confirmList.includes(3) || !confirmList.includes(4))"
        >
          <rule
            @loadData="loadDataOver($event, { typeId: 0 })"
            :is-preview="isPreview"
            @tableConfirm="tableConfirm"
            :props-filters="{ typeId: 0 }"
            :propsIpagination="ipagination"
          ></rule>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
</template>

<script>
import Guide from '@/views/system/modules/guide/guide-base'
import Rule from '@/views/system/Rule'
export default {
  components: {
    Guide,
    Rule,
  },
  props: {
    isPreview: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      url: {
        list: '/guide/list',
        delete: '/guide/delete',
        deleteBatch: '/guide/delete_batch',
        edit: '/guide/edit',
        add: '/guide/add',
      },
      ipagination: { current: 1, pageSize: -1 },
      currentTabIndex: '1',
      confirmList: [],
    }
  },
  methods: {
    tabChange(key) {
      this.currentTabIndex = key
    },
    tableConfirm({ typeId }) {
      this.confirmList = [...new Set([...this.confirmList, typeId])]
      console.log({ 'this.confirmList': this.confirmList })
      this.resolveAllConfirm()
    },
    resolveAllConfirm() {
      // [0,2,3,4]
      const allTypeId = Array.from(Array(5), (_, idx) => idx).filter((v) => v != 1)
      console.log({ allTypeId })
      let allTableConfirm = allTypeId.every((i) => this.confirmList.includes(i))
      if (allTableConfirm) {
        console.log('.所有表格都勾选啦！！！')
        this.$emit('allTableConfirm')
      }
    },
    loadDataOver(dataSource, { typeId }) {
      console.log('loadDataOver', { dataSource, typeId })
      if (dataSource && !dataSource.length) {
        console.log(`typeId为${typeId}的表格没有数据！！！默认为已经点了确认了`)
        this.confirmList = [...new Set([...this.confirmList, typeId])]
        console.log({ 'this.confirmList': this.confirmList })
        this.resolveAllConfirm()
      }
    },
  },
  created() {
    console.log('dakjhd')
  },
}
</script>

<style lang='less' scoped>
.inline-tab-view {
  .ant-card {
    .ant-card-body {
      padding: unset;
    }
  }
}
/deep/ .ant-tabs{
  min-height:450px;
}
</style>
